import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBMYoLeEZObgHbh6dfXCDPI1Dh0nFuN8vI",
  authDomain: "tutorial-push-web-cf6bb.firebaseapp.com",
  projectId: "tutorial-push-web-cf6bb",
  storageBucket: "tutorial-push-web-cf6bb.appspot.com",
  messagingSenderId: "743057184715",
  appId: "1:743057184715:web:80f0c2e39efd5fd6ab884e",
  measurementId: "G-NNJ1R4P0GD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
