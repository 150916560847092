import { getAuth, signInAnonymously } from "firebase/auth";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase";
import { useEffect, useState } from "react";

import Logo from "./logo.svg";

function App() {
  const [token, setToken] = useState();
  const loguearse = () => {
    signInAnonymously(getAuth()).then((usuario) => console.log(usuario));
  };

  const activarMensajes = async () => {
    console.log(messaging);
    const tokenn = await getToken(messaging, {
      vapidKey:
        "BGWs89EzKcBEW4N36F9Y0CYj97KtS20z4-Jg4WuBxiAZNUD4XGNqaNZnqN8Wz3q6qm1roLXosILN5h-QCKd4ssw",
    }).catch((error) => console.log(error));

    if (tokenn) {
      setToken(tokenn);
      console.log("tu token: ", tokenn);
    }
    if (!token) console.log("No teni token");
  };

  useEffect(() => {
    onMessage(messaging, (message) => {
      console.log("Tu mensaje: ", message);
      if (Notification.permission === "granted") {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(message.notification.title, {
            body: message.notification.body,
            icon: message.notification.icon,
            actions: [{ action: "open_url", title: "Visitar" }],
          });
        });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // if (permission === "granted") {
          //   console.log("Notification permission granted.");
          //   navigator.serviceWorker.ready.then(function (registration) {
          //     registration.showNotification(message.notification.title, {
          //       body: message.notification.body,
          //       icon: message.notification.icon,
          //       actions: [{ action: "open_url", title: "Visitar" }],
          //     });
          //   });
          // } else {
          //   console.log("Notification permission denied.");
          // }
        });
      }
    });
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(registration);
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch(function (error) {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);
  const requestNotificationPermission = () => {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification("Notificación de prueba", {
            body: "Esto es un mensaje de prueba",
            icon: "/path/to/icon.png",
            badge: "/path/to/badge.png",
          });
        });
      } else {
        console.log("Notification permission denied.");
      }
    });
  };
  const handleNotification = () => {
    if (Notification.permission === "granted") {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification("Notificacion de Ejemplo", {
          body: "Mensaje de texto",
          icon: Logo,
        });
      });
    } else if (Notification.permission !== "denied") {
      requestNotificationPermission();
    }
  };

  return (
    <div>
      <h1>Pruebas Web Push</h1>
      <div>
        <button onClick={activarMensajes}>Generar token</button>
        <button onClick={loguearse}>Loguearse</button>
      </div>
      <p>{token}</p>
    </div>
  );
}

export default App;
